import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./../dashboard/dashBoard.scss";
import CancelIcon from "@mui/icons-material/Cancel";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { ApiBaseUrl } from "../../../../BaseUrl";

const HostAvailbilty = () => {
  const [getSelcetedDates, setGetSelectedDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const HOST_ID = localStorage.getItem("PARENT_ID");

  useEffect(() => {
    // Retrieve the access token from local storage
    const accessToken = localStorage.getItem("ACCESS_TOKEN");

    // Set the bearer token in the request headers
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    axios.get(`${ApiBaseUrl}/calenderview/${HOST_ID}`, config).then((response) => {
      setGetSelectedDates(response.data);
    });
  }, [HOST_ID]);

  const formatDate = (dateString) => {
    const parts = dateString.split("/"); // Split the date string into parts
    const day = parts[1];
    const month = parts[0];
    const year = parts[2];

    // Create a new date in the desired format (DD/MM/YYYY)
    return `${day}/${month}/${year}`;
  };

  const handleDateChange = (date) => {
    // Retrieve the access token from local storage
    const accessToken = localStorage.getItem("ACCESS_TOKEN");

    // Set the bearer token in the request headers
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    setSelectedDates((prevDates) => [...prevDates, date]);
    const formattedDates = [...selectedDates, date].map((d) =>
      d.toLocaleDateString("en-US")
    );
    const requestBody = {
      available_days: formattedDates.join(","), // Convert selected dates to a comma-separated string
      non_available_days: 0, // You can adjust this if needed
    };

    // Make a POST request to your API with the selected dates
    axios
      .post(`${ApiBaseUrl}/calenderview/${HOST_ID}`, requestBody, config)
      .then((response) => {
        // Handle the response from the API if necessary
        setGetSelectedDates(response.data);
      })
      .catch((error) => {
        // Handle errors if the POST request fails
        console.error("Error making POST request", error);
      });
  };

  const removeDate = (dateToRemove) => {
    // Show a confirmation dialog
    toast
      .promise(
        // Promise that resolves when the user clicks "Confirm"
        new Promise((resolve) => {
          // Display a confirmation toast
          toast.warning("Are you sure you want to delete this date?", {
            autoClose: false,
            position: "top-right",
            closeButton: false,
            onClose: resolve,
          });
        }),
        {
          pending: "Waiting for confirmation...",
          success: "Date deleted!",
          error: "Date not deleted!",
        }
      )
      .then((result) => {
        // If the user confirms, perform the delete action
        if (result && result.dismiss !== "confirm") {
          return;
        }

        // Retrieve the access token from local storage
        const accessToken = localStorage.getItem("Access_Token");

        // Set the bearer token in the request headers
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };

        axios
          .delete(
            `/calenderview/${HOST_ID}?date_to_delete=${dateToRemove}`,
            config
          )
          .then(() => {
            // Filter out the removed date from the selectedDates state
            const updatedDates = selectedDates.filter(
              (date) => date.toDateString() !== dateToRemove
            );
            setSelectedDates(updatedDates);
            window.location.reload();
          })
          .catch((error) => {
            // Handle errors if the DELETE request fails
            console.error("Error making DELETE request", error);
            toast.error("Date deletion failed");
          });
      });
  };

  return (
    <>
      <div className="adjustavailabilty">
        <div className="adjustcontainer">
          <h5>Adjust your availability</h5>
          <h6>You can plan when you are available to host pets</h6>
          <div className="calneder">
            <div className="calender_main">
              <DatePicker
                selected={null} // Set selected to null to clear the input field
                onChange={handleDateChange}
                isClearable
                showMonthDropdown
                showYearDropdown
                dropdownMode="false"
                dateFormat="dd/MM/yyyy"
                placeholderText="Select dates..."
                calendarClassName="date-picker-calendar"
                inline
                selectsRange={false}
                className="react-datepicker"
                dayClassName={(date) => {
                  // Check if the date is in the selectedDates array and apply the green background style
                  return selectedDates.find(
                    (selectedDate) =>
                      date.toDateString() === selectedDate.toDateString()
                  )
                    ? "green-background"
                    : "";
                }}
              />
            </div>
            <div className="calender_heading">
              <h5>Adjust Date</h5>
              <p>I'm available after</p>
              <ul className="data-date ">
                {getSelcetedDates.available_days &&
                  getSelcetedDates.available_days
                    .split(",")
                    .map((date, index) => (
                      <li key={index}>
                        {formatDate(date.trim())}
                        <CancelIcon
                          className="cancel-icon"
                          onClick={() => removeDate(date.trim())}
                        />
                      </li>
                    ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HostAvailbilty;
