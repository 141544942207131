// import React, { Suspense, lazy, useContext } from "react";
// import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
// import "./App.css";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { AuthContext } from "./modules/admin/AuthContext";
// import HostDashboard from "./modules/hosting-module/host-dashboard/HostDashboard";
// import Dashboard from "./modules/hosting-module/host-dashboard/dashboard/DashBoard";
// import HostAvailbilty from "./modules/hosting-module/host-dashboard/availability/HostAvailability";
// import HostBookings from "./modules/hosting-module/host-dashboard/host-bookings/HostBookings";
// import CommunityHome from "./modules/community-module/CommunityHome";
// import SelectPetToHost from "./modules/hosting-module/forms/booking-form/SelectPetToHost";
// import OrderDetails from "./modules/hosting-module/forms/booking-form/OrderDetails";
// import OrderSummary from "./modules/hosting-module/forms/booking-form/OrderSummary";
// import FilteredHosts from "./modules/hosting-module/pages/home-page/sections/filtered-hosts/FilteredHosts";
// import AllStarHosts from "./modules/hosting-module/pages/all-hosts/AllStarHosts";
// import AllPopularHosts from "./modules/hosting-module/pages/all-hosts/AllPopularHosts";
// import AllNearByHosts from "./modules/hosting-module/pages/all-hosts/AllNearByHosts";
// import AllHostsByPrice from "./modules/hosting-module/pages/all-hosts/AllHostsByPrice";

// const Home = lazy(() =>
//   import("./modules/hosting-module/pages/home-page/Home")
// );
// const Login = lazy(() => import("./authentication/login/Login"));
// const HostProfile = lazy(() =>
//   import("./modules/hosting-module/pages/profiles/host-profile/HostProfile")
// );
// const PetProfile = lazy(() =>
//   import("./modules/hosting-module/pages/profiles/pet-profile/PetProfile")
// );
// const CompleteParentProfile = lazy(() =>
//   import(
//     "./modules/hosting-module/forms/complete-profile/CompleteParentProfile"
//   )
// );
// const ParentProfile = lazy(() =>
//   import("./modules/hosting-module/pages/profiles/parent-profile/ParentProfile")
// );
// const BecomeHostForm = lazy(() =>
//   import("./modules/hosting-module/forms/become-host-form/BecomeHostForm")
// );
// const AllBlogs = lazy(() =>
//   import("./modules/hosting-module/pages/blogs/AllBlogs")
// );
// const OneBlog = lazy(() =>
//   import("./modules/hosting-module/pages/blogs/OneBlog")
// );
// const AddPet = lazy(() =>
//   import("./modules/hosting-module/forms/add-pet-form/AddPet")
// );

// const AdminLogin = React.lazy(() => import("./modules/admin/admin-login"));
// const AdminPanel = React.lazy(() => import("./modules/admin/main-panel"));
// const CreateBlogBlog = React.lazy(() =>
//   import("./modules/admin/createBlogMain")
// );
// const BlogBlogEdit = React.lazy(() => import("./modules/admin/editBlog"));
// const SeoList = React.lazy(() => import("./modules/admin/seo-list"));
// const PurposeSeo = React.lazy(() => import("./modules/admin/ManageSeo"));
// const EditSeo = React.lazy(() => import("./modules/admin/edit-seo"));

// function App() {
//   const customToastStyle = {
//     top: "30%",
//     left: "75%",
//     transform: "translate(-50%, -50%)",
//   };
//   const { isLoggedIn } = useContext(AuthContext);
//   return (
//     <BrowserRouter>
//       <Suspense fallback={<div>Loading...</div>}>
//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="/login" element={<Login />} />
//           <Route path="/host/:hostId" element={<HostProfile />} />
//           <Route path="/pet/:petId" element={<PetProfile />} />
//           <Route path="/parent-profile" element={<ParentProfile />} />
//           <Route
//             path="/update-profile/:id"
//             element={<CompleteParentProfile />}
//           />
//           <Route path="/become-host" element={<BecomeHostForm />} />
//           <Route path="/add-pet" element={<AddPet />} />
//           <Route path="/blogs" element={<AllBlogs />} />
//           <Route path="/blogs/:slug" element={<OneBlog />} />

//           {/* * * * * * * * * A L L - H O S T S - R O U T E S * * * * * * * * */}
//           <Route path="/all-star-hosts" element={<AllStarHosts />} />
//           <Route path="/all-best-hosts" element={<AllPopularHosts />} />
//           <Route path="/all-near-by-hosts" element={<AllNearByHosts />} />
//           <Route path="/all-hosts-by-price" element={<AllHostsByPrice />} />

//           {/* * * * * * * * * O R D E R - F L O W - R O U T E S * * * * * * * * */}
//           <Route path="/select-pet-to-host" element={<SelectPetToHost />} />
//           <Route path="/order-details" element={<OrderDetails />} />
//           <Route path="/order-summary" element={<OrderSummary />} />

//           {/* * * * * * * * * H O S T - D A S H B O A R D - R O U T E S * * * * * * * * */}
//           <Route path="/host" element={<HostDashboard />}>
//             <Route index element={<Dashboard />} />
//             <Route path="/host/host-dashboard" element={<Dashboard />} />
//             <Route path="/host/host-availabilty" element={<HostAvailbilty />} />
//            <Route path="/host/my-bookings" element={<HostBookings />} />
//              {/* <Route path="/host/create_invoice" element={<CreateInvoice />} /> */}
//           </Route>

//           {/* * * * * * * * * C O M M U N I T Y - R O U T E S * * * * * * * * */}
//           <Route path="/community" element={<CommunityHome />} />
//           <Route path="/filtered-hosts" element={<FilteredHosts />} />

//           {/* * * * * * * * * A D M I N - P A N E L - R O U T E S * * * * * * * * */}
//           <Route path="/admin" element={<AdminLogin />} />
//           <Route
//             path="/admin-panel"
//             element={isLoggedIn ? <AdminPanel /> : <Navigate to="/" />}
//           />
//           <Route exact path="/blog-create" element={<CreateBlogBlog />} />
//           <Route exact path="/blog-edit/:blog_id" element={<BlogBlogEdit />} />
//           <Route exact path="/seo-list" element={<SeoList />} />
//           <Route exact path="/seo-create" element={<PurposeSeo />} />
//           <Route exact path="/seo-edit/:slug_id" element={<EditSeo />} />
//         </Routes>
//       </Suspense>
//     </BrowserRouter>
//   );
// }

// export default App;

// L O G I C - T O - R E D I R E C T - T O - A P P - D O W N L O A D - P A G E - O N - S M A L L - S C R E E N
import React, { Suspense, lazy, useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "./modules/admin/AuthContext";
import { isMobile, isTablet } from "react-device-detect";

import HostDashboard from "./modules/hosting-module/host-dashboard/HostDashboard";
import Dashboard from "./modules/hosting-module/host-dashboard/dashboard/DashBoard";
import HostAvailbilty from "./modules/hosting-module/host-dashboard/availability/HostAvailability";
import HostBookings from "./modules/hosting-module/host-dashboard/host-bookings/HostBookings";
import CommunityHome from "./modules/community-module/CommunityHome";
import SelectPetToHost from "./modules/hosting-module/forms/booking-form/SelectPetToHost";
import OrderDetails from "./modules/hosting-module/forms/booking-form/OrderDetails";
import OrderSummary from "./modules/hosting-module/forms/booking-form/OrderSummary";
import FilteredHosts from "./modules/hosting-module/pages/home-page/sections/filtered-hosts/FilteredHosts";
import AllStarHosts from "./modules/hosting-module/pages/all-hosts/AllStarHosts";
import AllPopularHosts from "./modules/hosting-module/pages/all-hosts/AllPopularHosts";
import AllNearByHosts from "./modules/hosting-module/pages/all-hosts/AllNearByHosts";
import AllHostsByPrice from "./modules/hosting-module/pages/all-hosts/AllHostsByPrice";
import AppDownload from "./modules/hosting-module/components/app-download/AppDownload";
import Protected from "./Protected";
import FavHosts from "./modules/hosting-module/pages/fav-hosts/FavHosts";
import MyBookings from "./modules/hosting-module/pages/my-bookings/MyBookings";
import TermsConditions from "./modules/hosting-module/pages/terms-conditions/TermsConditions";
import PrivacyPolicy from "./modules/hosting-module/pages/privacy-policy/PrivacyPolicy";
import UpdatePet from "./modules/hosting-module/forms/update-pet-form/UpdatePet";

const Home = lazy(() =>
  import("./modules/hosting-module/pages/home-page/Home")
);
const Login = lazy(() => import("./authentication/login/Login"));
const HostProfile = lazy(() =>
  import("./modules/hosting-module/pages/profiles/host-profile/HostProfile")
);
const PetProfile = lazy(() =>
  import("./modules/hosting-module/pages/profiles/pet-profile/PetProfile")
);
const CompleteParentProfile = lazy(() =>
  import(
    "./modules/hosting-module/forms/complete-profile/CompleteParentProfile"
  )
);
const ParentProfile = lazy(() =>
  import("./modules/hosting-module/pages/profiles/parent-profile/ParentProfile")
);
const BecomeHostForm = lazy(() =>
  import("./modules/hosting-module/forms/become-host-form/BecomeHostForm")
);
const AllBlogs = lazy(() =>
  import("./modules/hosting-module/pages/blogs/AllBlogs")
);
const OneBlog = lazy(() =>
  import("./modules/hosting-module/pages/blogs/OneBlog")
);
const AddPet = lazy(() =>
  import("./modules/hosting-module/forms/add-pet-form/AddPet")
);

const AdminLogin = React.lazy(() => import("./modules/admin/admin-login"));
const AdminPanel = React.lazy(() => import("./modules/admin/main-panel"));
const CreateBlogBlog = React.lazy(() =>
  import("./modules/admin/createBlogMain")
);
const BlogBlogEdit = React.lazy(() => import("./modules/admin/editBlog"));
const SeoList = React.lazy(() => import("./modules/admin/seo-list"));
const PurposeSeo = React.lazy(() => import("./modules/admin/ManageSeo"));
const EditSeo = React.lazy(() => import("./modules/admin/edit-seo"));

const AppDownloadPage = () => {
  return <div>App Download Page</div>;
};

const isDevice = () => {
  return isMobile || isTablet || navigator.userAgent.match(/iPad/i);
};

function App() {
  const customToastStyle = {
    top: "30%",
    left: "75%",
    transform: "translate(-50%, -50%)",
  };

  const { isLoggedIn } = useContext(AuthContext);

  const isMobileOrTablet = isDevice();

  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {/* If on mobile/tablet and not accessing the blog page, redirect to app download */}
          <Route
            path="/"
            element={
              isMobileOrTablet ? <Navigate to="/app-download" /> : <Home />
            }
          />
          <Route
            path="/login"
            element={
              isMobileOrTablet ? <Navigate to="/app-download" /> : <Login />
            }
          />
          <Route
            path="/host/:hostId"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <HostProfile />
              )
            }
          />
          <Route
            path="/pet/:petId"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<PetProfile />} />
              )
            }
          />
          <Route
            path="/parent-profile"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<ParentProfile />} />
              )
            }
          />

          <Route
            path="/favourite-hosts"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<FavHosts />} />
              )
            }
          />

          <Route
            path="/my-bookings"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<MyBookings />} />
              )
            }
          />

          <Route
            path="/update-profile/:id"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<CompleteParentProfile />} />
              )
            }
          />

          <Route
            path="/update-pet-profile/:PetId"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<UpdatePet />} />
              )
            }
          />
          <Route
            path="/become-host"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<BecomeHostForm />} />
              )
            }
          />
          <Route
            path="/add-pet"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<AddPet />} />
              )
            }
          />

          {/* Blog routes accessible on all devices */}
          <Route path="/terms-and-conditions" element={<TermsConditions />} />
          <Route path="/privacy-and-policy" element={<PrivacyPolicy />} />
          <Route path="/cancellation-and-refund-policy" element={<AllBlogs />} />
          <Route path="/blogs" element={<AllBlogs />} />
          <Route path="/blogs/:slug" element={<OneBlog />} />

          {/* All Hosts Routes */}
          <Route
            path="/all-star-hosts"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <AllStarHosts />
              )
            }
          />
          <Route
            path="/all-best-hosts"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <AllPopularHosts />
              )
            }
          />
          <Route
            path="/all-near-by-hosts"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <AllNearByHosts />
              )
            }
          />
          <Route
            path="/all-hosts-by-price"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <AllHostsByPrice />
              )
            }
          />

          {/* Order Flow Routes */}
          <Route
            path="/select-pet-to-host"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<SelectPetToHost />} />
              )
            }
          />
          <Route
            path="/order-details"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<OrderDetails />} />
              )
            }
          />
          <Route
            path="/order-summary"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<OrderSummary />} />
              )
            }
          />

          {/* Host Dashboard Routes */}
          <Route
            path="/host"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<HostDashboard />} />
              )
            }
          >
            <Route index element={<Dashboard />} />
            <Route
              path="/host/host-dashboard"
              element={
                isMobileOrTablet ? (
                  <Navigate to="/app-download" />
                ) : (
                  <Protected cmp={<Dashboard />} />
                )
              }
            />
            <Route
              path="/host/host-availabilty"
              element={
                isMobileOrTablet ? (
                  <Navigate to="/app-download" />
                ) : (
                  <Protected cmp={<HostAvailbilty />} />
                )
              }
            />
            <Route
              path="/host/my-bookings"
              element={
                isMobileOrTablet ? (
                  <Navigate to="/app-download" />
                ) : (
                  <Protected cmp={<HostBookings />} />
                )
              }
            />
          </Route>

          {/* Community Routes */}
          <Route
            path="/community"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <CommunityHome />
              )
            }
          />
          <Route
            path="/filtered-hosts"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <FilteredHosts />
              )
            }
          />

          {/* Admin Panel Routes */}
          <Route
            path="/admin"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <AdminLogin />
              )
            }
          />
          <Route
            path="/admin-panel"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : isLoggedIn ? (
                <AdminPanel />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/blog-create"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <CreateBlogBlog />
              )
            }
          />
          <Route
            exact
            path="/blog-edit/:blog_id"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <BlogBlogEdit />
              )
            }
          />
          <Route
            exact
            path="/seo-list"
            element={
              isMobileOrTablet ? <Navigate to="/app-download" /> : <SeoList />
            }
          />
          <Route
            exact
            path="/seo-create"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <PurposeSeo />
              )
            }
          />
          <Route
            exact
            path="/seo-edit/:slug_id"
            element={
              isMobileOrTablet ? <Navigate to="/app-download" /> : <EditSeo />
            }
          />

          {/* App Download Page */}
          <Route path="/app-download" element={<AppDownload />} />

          {/* Redirect any unmatched routes to app download page on mobile/tablet */}
          <Route
            path="*"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Navigate to="/" />
              )
            }
          />
        </Routes>
      </Suspense>
      <ToastContainer position="top-right" style={customToastStyle} />
    </BrowserRouter>
  );
}

export default App;
