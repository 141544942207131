import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiBaseUrl } from "../../../BaseUrl";

// Action to Get Otp::
export const getOtpRequest = createAsyncThunk(
  "getOtpRequest",
  async (userInputData) => {
    const response = await axios.post(`${ApiBaseUrl}/otp-send`, userInputData);
    return response.data;
  }
);

// Action to verify Otp::
export const verifyOtpRequest = createAsyncThunk(
  "verifyOtpRequest",
  async (userOtp) => {
    const response = await axios.post(`${ApiBaseUrl}/otp-verify`, userOtp);
    return response.data;
  }
);

// Action to logOut user::
export const logOut = createAsyncThunk("logOut", () => {
  localStorage.removeItem("isUserLoggedIn");
});

const loginSlice = createSlice({
  name: "login",
  initialState: {
    otpSending: false,
    otpSent: false,
    otpVerifying: false,
    otpVerified: false,
    isAuthenticated: false,
    userData: null,
    otpError: null,
    verificationError: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getOtpRequest.pending, (state) => {
      state.otpSending = true;
    });
    builder.addCase(getOtpRequest.fulfilled, (state, action) => {
      state.otpSent = true;
    });
    builder.addCase(getOtpRequest.rejected, (state, action) => {
      state.otpSending = false;
      state.error = action.payload;
    });
    builder.addCase(verifyOtpRequest.pending, (state) => {
      state.otpVerifying = true;
    });
    builder.addCase(verifyOtpRequest.fulfilled, (state, action) => {
      state.otpVerified = true;
      state.isAuthenticated = true;
      state.userData = action.payload;
      localStorage.setItem("isUserLoggedIn", true);
      localStorage.setItem("ACCESS_TOKEN", state.userData.Jwt.access);
      localStorage.setItem("PARENT_ID", state.userData.client.id);
      localStorage.setItem("ROLE", state.userData.client.role);
      localStorage.setItem("PROFILE_PIC", state.userData.client.profile_pic);
    });
    builder.addCase(verifyOtpRequest.rejected, (state, action) => {
      state.otpVerifying = false;
      state.error = action.payload;
    });
  },
});

export default loginSlice.reducer;
