import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
import { BsDot } from "react-icons/bs";
import { FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <>
      <section className="footer-section-wrapper">
        <div className="footer-top-section flex-center">
          <ul>
            <li>Support</li>
            <li>
              <a href="/">Help Centre</a>
            </li>
            <li>
              <a href="/">Woofily Cover</a>
            </li>
            <li>
              <a href="/">Refund Options</a>
            </li>
            <li>
              <a href="/">Cancellation Options</a>
            </li>
            <li>
              <a href="/">Report Concern</a>
            </li>
          </ul>
          <ul>
            <li>Hosting</li>
            <li>
              <a href="/">Become Host</a>
            </li>
            <li>
              <a href="/">Host Your Pet</a>
            </li>
            <li>
              <a href="/">Hosting Resources</a>
            </li>
            <li>
              <a href="/">Hosting Responsibly</a>
            </li>
          </ul>
          <ul>
            <li>Woofily</li>
            <li>
              <Link to="/blogs">Blogs</Link>
            </li>
            <li>
              <a href="/">New features</a>
            </li>
            <li>
              <a href="/">Careers</a>
            </li>
            <li>
              <a href="/">Investors</a>
            </li>
            <li>
              <a href="/">Terms and Conditions</a>
            </li>
          </ul>
        </div>
        <div className="mobile-extended-footer flex-center">
          <p className="privacy flex-center">
            <BsDot className="dot-icon" />
            <Link to="/privacy-and-policy">Privacy & Policy</Link>
          </p>
          <p className="terms flex-center">
            <BsDot className="dot-icon" />
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
          </p>
        </div>
        <div className="footer-bottom-section flex-center">
          <div className="bottom-footer-left-section flex-center">
            <p className="copywright">© 2024 Woofily Private Limited</p>
            <p className="privacy flex-center">
              <BsDot className="dot-icon" />
              <Link to="/privacy-and-policy">Privacy & Policy</Link>
            </p>
            <p className="terms flex-center">
              <BsDot className="dot-icon" />
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
            </p>
          </div>
          <div className="bottom-footer-right-section flex-center">
            <div className="social-icon instagram flex-center">
              <a
                href="https://www.instagram.com/woof.ily/?igsh=ZG10NTJmazBhem1l"
                target="blank"
              >
                <FaInstagram className="icon" />
              </a>
            </div>
            <div className="social-icon facebook flex-center">
              <a
                href="https://www.facebook.com/Woofily?mibextid=LQQJ4d"
                target="blank"
              >
                <FaFacebookF className="icon" />
              </a>
            </div>
            <div className="social-icon linkedin flex-center">
              <a
                href="https://www.linkedin.com/company/woof-ily/"
                target="blank"
              >
                <FaLinkedinIn className="icon" />
              </a>
            </div>
            {/* <div className="social-icon twitter flex-center">
              <a href="/">
                <FaXTwitter className="icon" />
              </a>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
