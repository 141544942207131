import React, { useEffect, useRef, useState } from "react";
import "./header.scss";
import { TfiMenuAlt } from "react-icons/tfi";
import { IoClose } from "react-icons/io5";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IoPerson, IoPawSharp } from "react-icons/io5";
import { CiLogout, CiLogin } from "react-icons/ci";
import { RiUserAddFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { PiUserSwitchBold } from "react-icons/pi";
import { isAllowedToHost } from "../../../../redux/slices/host/IsAllowedToHostSlice";
import axios from "axios";
import { ApiBaseUrl } from "../../../../BaseUrl";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaHome } from "react-icons/fa";
import MyLocation from "../MyLocation/MyLocation";
import { FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { fetchParentProfileData } from "../../../../redux/slices/profile/ParentProfileSlice";
import { MdAssignmentLate } from "react-icons/md";


const Header = () => {
  const [sideBarStyle, setSideBarStyle] = useState({
    right: "-100%",
  });
  const sidebarRef = useRef(null);
  const [isHost, setIsHost] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isUserLoggedIn = localStorage.getItem("isUserLoggedIn");

  const logOut = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  // const { isLoading, isHost, error } = useSelector((state) => state.isHost);

  const CLIENT_ID = localStorage.getItem("PARENT_ID");
  const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");
  const CONFIG = {
    headers: {
      Authorization: `Bearer ${ACCESS_TOKEN}`,
    },
  };

  useEffect(() => {
    const isAllowedToHost = async () => {
      try {
        setLoading(true); // Set loading state to true before making the request
        const response = await axios.get(
          `${ApiBaseUrl}/is-allowed-to-host/${CLIENT_ID}`,
          CONFIG
        );
        setIsHost(response.data[0].is_allowed_to_host); // Set data with the response
        console.log(response.data[0].is_allowed_to_host); // Set data with the response
      } catch (err) {
        setError(err.message); // Set error state if there is an error
      } finally {
        setLoading(false); // Set loading state to false once the request is completed
      }
    };
    isAllowedToHost(); // Call the async function inside useEffect
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  // useEffect(() => {
  //   dispatch(isAllowedToHost(CLIENT_ID));
  //   // console.log(CLIENT_ID)
  // }, []);

  const currentRole = localStorage.getItem("ROLE");
  const SwitchToHost = async () => {
    try {
      var PARENT_ID = localStorage.getItem("PARENT_ID");

      // Retrieve the access token from local storage
      const accessToken = localStorage.getItem("ACCESS_TOKEN");

      // Set the bearer token in the request headers
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json", // Moved Content-Type to the headers object
        },
      };

      if (!PARENT_ID) {
        console.log("PARENT_ID is null, cannot switch roles");
        return;
      }

      const newRole = currentRole === "HOST" ? "PARENT" : "HOST";

      const response = await axios.put(
        `${ApiBaseUrl}/switch/${PARENT_ID}`,
        { role: newRole }, // Changed 'PARENT' to 'HOST' when switching roles
        config // Use the config object for headers
      );

      if (response.status === 200) {
        localStorage.setItem("ROLE", newRole); // Update role in localStorage
        // setShowBecomeHostButton(false);

        // Update role in sessionStorage;
        if (response.data.status === "ok") {
          sessionStorage.clear();

          // Navigate to the home page
          navigate("/host"); // Assuming history is available

          // window.location.reload();
        }
      } else {
        console.error("Role switch failed");
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  // S W I T C H - T 0 - P A R E N T - F U N C T I O N A L I T Y
  const SwitchToParent = async () => {
    try {
      var HOST_ID = localStorage.getItem("PARENT_ID");

      // Retrieve the access token from local storage
      const accessToken = localStorage.getItem("ACCESS_TOKEN");

      // Set the bearer token in the request headers
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json", // Moved Content-Type to the headers object
        },
      };

      if (!HOST_ID) {
        console.log("PARENT_ID is null, cannot switch roles");
        return;
      }

      const newRole = currentRole === "HOST" ? "PARENT" : "HOST";

      const response = await axios.put(
        `${ApiBaseUrl}/switch/${HOST_ID}`,
        { role: newRole }, // Changed 'PARENT' to 'HOST' when switching roles
        config // Use the config object for headers
      );

      if (response.status === 200) {
        localStorage.setItem("ROLE", newRole); // Update role in localStorage
        // setShowBecomeHostButton(false);

        // Update role in sessionStorage;
        if (response.data.status === "ok") {
          sessionStorage.clear();

          // Navigate to the home page
          navigate("/"); // Assuming history is available

          // window.location.reload();
        }
      } else {
        console.error("Role switch failed");
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  const handleClickOutside = (event) => {
    // Check if the click is outside the sidebar
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSideBarStyle({ right: "-100%" });
    }
  };

  useEffect(() => {
    // Add the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const PROFILE_PIC = localStorage.getItem("PROFILE_PIC")

  return (
    <>
      <section className="header-container">
        <div className="top-header-section flex-center">
          <div className="top-left-container flex-center">
            <img
              onClick={() => navigate("/")}
              src="/assets/images/woofily-logo.png"
              alt=""
            />
          </div>
          <div className="top-mid-container flex-center">
            <div className="my-location-wrapper">
              <MyLocation />
            </div>
            <div className="menu">
              <NavLink
                className="sec_change_btn"
                activeClassName="active"
                // to="/about-us"
                to="/"
              >
                {/* <FaPeopleGroup className="btn_icon" /> */}
                <FaHome className="btn_icon" />
                Home
              </NavLink>

              <NavLink
                className="sec_change_btn"
                activeClassName="active"
                to="/community"
              >
                <FaPeopleGroup className="btn_icon" />
                Community
              </NavLink>
            </div>
          </div>
          <div className="top-right-container flex-center">
            {isUserLoggedIn && (
              <div className="profile-icon-container" onClick={() => {navigate("/parent-profile")}}>
                <img
                  src={
                    PROFILE_PIC ? `${ApiBaseUrl}${PROFILE_PIC}`
                      : "/assets/images/profile-icon.png"
                  }
                  alt=""
                />
              </div>
            )}
            <div className="menu-btn-container flex-center">
              <TfiMenuAlt
                onClick={() => {
                  setSideBarStyle({ right: "0" });
                }}
                className="menu-btn-icon"
              />
            </div>
          </div>
        </div>
        {/* <div className="bottom-header-section flex-center">
          <div className="menu">
            {isHost ? (
              <NavLink
                className="sec_change_btn"
                activeClassName="active"
                onClick={
                  currentRole === "PARENT" ? SwitchToHost : SwitchToParent
                }
              >
                <PiUserSwitchBold className="btn_icon" />
                Switch to {currentRole === "PARENT" ? "host" : "parent"}
              </NavLink>
            ) : (
              <NavLink
                className="sec_change_btn"
                activeClassName="active"
                to="/become-host"
              >
                <RiUserAddFill className="btn_icon" />
                Become a Host
              </NavLink>
            )}

            <NavLink
              className="sec_change_btn"
              activeClassName="active"
              to="/community"
            >
              <FaPeopleGroup className="btn_icon" />
              Community
            </NavLink>
          </div>
        </div> */}
      </section>

      <section
        ref={sidebarRef}
        className="sidebar-container"
        style={sideBarStyle}
      >
        <div className="close-sidebar-btn-container">
          <IoClose
            onClick={() => {
              setSideBarStyle({ right: "-100%" });
            }}
            className="close-sidebar-btn"
          />
        </div>
        <ul className="navigation-links-list">
          {!isUserLoggedIn ? (
            <li className="navigation-link">
              <CiLogin className="nav-link-icon" />
              <Link to="/login">Login</Link>
            </li>
          ) : (
            <li className="navigation-link">
              <IoPerson className="nav-link-icon" />
              <Link to="/parent-profile">Profile</Link>
            </li>
          )}
          {isHost ? (
            <li className="navigation-link">
              <PiUserSwitchBold className="nav-link-icon" />
              <Link
                onClick={
                  currentRole === "PARENT" ? SwitchToHost : SwitchToParent
                }
              >
                Switch to {currentRole === "PARENT" ? "host" : "parent"}
              </Link>
            </li>
          ) : (
            <li className="navigation-link">
              <RiUserAddFill className="nav-link-icon" />
              <Link to="/become-host">Become a host</Link>
            </li>
          )}
          {isUserLoggedIn ? (
            <li className="navigation-link">
              <IoPawSharp className="nav-link-icon" />
              <Link to="/favourite-hosts">Favourite Host</Link>
            </li>
          ) : null}
          {isUserLoggedIn ? (
            <li className="navigation-link">
              <MdAssignmentLate className="nav-link-icon" />
              <Link to="/my-bookings">My Bookings</Link>
            </li>
          ) : null}
          {isUserLoggedIn ? (
            <li className="navigation-link">
              <CiLogout className="nav-link-icon" />
              <Link onClick={logOut}>Log Out</Link>
            </li>
          ) : null}
        </ul>

        {/* <div className="side-bar-social-icons flex-center">
          <div className="social-icon instagram flex-center">
            <a
              href="https://www.instagram.com/woof.ily/?igsh=ZG10NTJmazBhem1l"
              target="blank"
            >
              <FaInstagram className="icon" />
            </a>
          </div>
          <div className="social-icon facebook flex-center">
            <a
              href="https://www.facebook.com/Woofily?mibextid=LQQJ4d"
              target="blank"
            >
              <FaFacebookF className="icon" />
            </a>
          </div>
          <div className="social-icon linkedin flex-center">
            <a href="https://www.linkedin.com/company/woof-ily/" target="blank">
              <FaLinkedinIn className="icon" />
            </a>
          </div>
        </div> */}
      </section>
    </>
  );
};

export default Header;
