import React, { useEffect, useState } from "react";
import "./favHosts.scss";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import HostCard from "../../components/home-host-card/HostCard";
import axios from "axios";
import { ApiBaseUrl } from "../../../../BaseUrl";

const FavHosts = () => {
  const [favouriteHosts, setFavouriteHosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // window.scrollTo(
    //   0,
    //   26 * parseFloat(getComputedStyle(document.documentElement).fontSize)
    // );
    GetAllFavouriteHosts();
  }, []);

  const GetAllFavouriteHosts = async () => {
    const PARENT_ID = localStorage.getItem("PARENT_ID");
    try {
      const result = await axios.get(
        `${ApiBaseUrl}/parent/${PARENT_ID}/${PARENT_ID}/favorites`
      );
      setFavouriteHosts(result.data);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="best_host_heading_wrapper" style={{marginTop: '55px'}}>
        <h2>My Favourites</h2>
      </div>

      <section className="third_section">
        <div className="third_section_contianer">
          <div className="card-best-host">
            {favouriteHosts.map((card, key) => (
              <HostCard
                key={card.host.client_id}
                id={card.host.client_id}
                name={card.host.name}
                role={card.host.role}
                profilePic={card.host.profile_pic}
                city={card.host.city}
                pricePerDay={card.host.amount_day}
                pricePerHour={card.host.amount_hr}
                rating={card.host.average_rating}
                distance={card.host.distance}
              />
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default FavHosts;
